// extracted by mini-css-extract-plugin
export var OurTeam = "OurTeam-module--OurTeam--mBDcm";
export var board = "OurTeam-module--board--2EHLC";
export var container = "OurTeam-module--container--oqOOM";
export var content = "OurTeam-module--content--9Tgp0";
export var founder = "OurTeam-module--founder--QCV3e";
export var foundingTeam = "OurTeam-module--foundingTeam--cj7xR";
export var image = "OurTeam-module--image--MWymu";
export var linkedInIcon = "OurTeam-module--linkedInIcon--j7EL5";
export var linkedInIconBoard = "OurTeam-module--linkedInIconBoard--aG0yp";
export var member = "OurTeam-module--member--5VM8S";
export var team = "OurTeam-module--team--seDI5";